import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin: 0 auto;
  color: white;
  width: 85%;

  @media only screen and (max-width: 850px) {
    max-width: 500px;
  }

  @media only screen and (max-width: 600px) {
    max-width: 400px;
  }
`

const Title = styled.div`
  margin: 120px 0 50px;

  .title {
    margin-bottom: 10px;
    font-family: Avenir, Helvetica, sans-serif;
  }
`

const BackImage = styled(Img)`
  height: 400px;
  width: 525px;
  margin-top: 70px;

  border-radius: 8px;
  box-shadow: 0 13px 27px -5px rgba(0, 0, 0, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);

  @media only screen and (max-width: 850px) {
    height: 300px;
    width: 393px;
  }

  @media only screen and (max-width: 600px) {
    height: 208px;
    width: 280px;
  }
`

const CircleImage = styled(Img)`
  height: 270px;
  width: 270px;
  position: absolute !important;
  bottom: -100px;
  left: ${p => p.right && "-50px"};
  right: ${p => p.left && "-50px"};
  border-radius: 50%;
  box-shadow: 0 13px 27px -5px rgba(20, 20, 20, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);

  @media only screen and (max-width: 850px) {
    height: 200px;
    width: 200px;

    bottom: -30px;
    left: ${p => p.right && "-25px"};
    right: ${p => p.left && "-25px"};
  }

  @media only screen and (max-width: 600px) {
    height: 150px;
    width: 150px;
    bottom: 80px;
    left: ${p => p.right && "-25px"};
    right: ${p => p.left && "-25px"};
  }
`

const ImageContainer = styled.div`
  position: relative;
  height: 350px;
`

const BandMember = styled.div`
  color: white;
  margin-bottom: 200px;
  width: fit-content;
  align-self: ${p => (p.left ? "flex-start" : "flex-end")};

  h2,
  p {
    text-align: ${p => (p.left ? "right" : "left")};
    transform: translateX(${p => (p.left ? "30px" : "-30px")});
  }

  h2 {
    color: #ffc66d;
  }

  @media only screen and (max-width: 850px) {
    margin-bottom: 100px;
  }

  @media only screen and (max-width: 600px) {
    margin-bottom: 0;
  }
`

const Band = ({ data }) => {
  return (
    <>
      <SEO title="Meet the Band" />
      <Wrapper>
        <Title>
          <h1 className="title">Meet</h1>
          <h4 className="title">the</h4>
          <h1 className="title">Band</h1>
        </Title>

        <BandMember left={false}>
          <h2>Ben Byrom</h2>
          <p>lead vocals, bass</p>
          <ImageContainer>
            <BackImage fluid={data.ben_bw.childImageSharp.fluid} />
            <CircleImage
              right={true}
              fluid={data.ben_square.childImageSharp.fluid}
            />
          </ImageContainer>
        </BandMember>

        <BandMember left={true}>
          <h2>David McMillan</h2>
          <p>banjo</p>
          <ImageContainer>
            <BackImage fluid={data.david_bw.childImageSharp.fluid} />
            <CircleImage
              left={true}
              fluid={data.david_square.childImageSharp.fluid}
            />
          </ImageContainer>
        </BandMember>

        <BandMember left={false}>
          <h2>Hailey McCulloch</h2>
          <p>violin, vocals</p>
          <ImageContainer>
            <BackImage fluid={data.hailey_bw.childImageSharp.fluid} />
            <CircleImage
              right={true}
              fluid={data.hailey_square.childImageSharp.fluid}
            />
          </ImageContainer>
        </BandMember>

        <BandMember left={true}>
          <h2>Andy Cooke</h2>
          <p>drums & percussion</p>
          <ImageContainer>
            <BackImage fluid={data.andy_bw.childImageSharp.fluid} />
            <CircleImage
              left={true}
              fluid={data.andy_square.childImageSharp.fluid}
            />
          </ImageContainer>
        </BandMember>

        <BandMember left={false}>
          <h2>Jordan Hensley</h2>
          <p>guitar, vocals</p>
          <ImageContainer>
            <BackImage fluid={data.jordan_bw.childImageSharp.fluid} />
            <CircleImage
              right={true}
              fluid={data.jordan_square.childImageSharp.fluid}
            />
          </ImageContainer>
        </BandMember>
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query {
    ben_bw: file(relativePath: { eq: "ben_bw.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ben_square: file(relativePath: { eq: "ben_square.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 300, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    david_bw: file(relativePath: { eq: "david_bw.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    david_square: file(relativePath: { eq: "david_square.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 300, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hailey_bw: file(relativePath: { eq: "hailey_bw.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hailey_square: file(relativePath: { eq: "hailey_square.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 300, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    andy_bw: file(relativePath: { eq: "andy_bw.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    andy_square: file(relativePath: { eq: "andy_square.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 300, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jordan_bw: file(relativePath: { eq: "jordan_bw.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jordan_square: file(relativePath: { eq: "jordan_square.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 300, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Band
